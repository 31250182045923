import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
import * as _uuid2 from "uuid";
var _uuid = "default" in _uuid2 ? _uuid2.default : _uuid2;
var exports = {};
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
var React = _interopDefault(_react);
var uuid = _uuid;
var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var objectWithoutProperties = function (obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};
var AlertCircleIcon = function AlertCircleIcon(_ref) {
  var _ref$color = _ref.color,
    color = _ref$color === undefined ? "currentColor" : _ref$color,
    _ref$size = _ref.size,
    size = _ref$size === undefined ? 24 : _ref$size,
    children = _ref.children,
    title = _ref.title,
    props = objectWithoutProperties(_ref, ["color", "size", "children", "title"]);
  var className = "mdi-icon " + (props.className || "");
  var titleId = title ? title.id ? title.id : uuid.v4() : null;
  return React.createElement("svg", _extends({}, props, {
    "aria-labelledby": titleId,
    className: className,
    width: size,
    height: size,
    fill: color,
    viewBox: "0 0 24 24"
  }), title && React.createElement("title", {
    id: titleId
  }, title.name), React.createElement("path", {
    d: "M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
  }));
};
var AlertCircleIcon$1 = React.memo ? React.memo(AlertCircleIcon) : AlertCircleIcon;
exports = AlertCircleIcon$1;
export default exports;